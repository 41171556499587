// This file should be ignored as it works as intended

export function clamp(val,min,max){
    if(val<min){
        return min;
    }else if(val>max){
        return max;
    }
    return val;
}
const FPS=50;
const TILL_TIMEOUT=1000;

//Pass a canvas,audio object and other parameters to make a visualizer
class Visualizer{
    constructor(canvas,audio,color,meters=30,gapPercentage=10,maxFrequency=200,cutEnd=0.1){
        this.canvas=canvas;
        this.canvasContext=canvas.getContext("2d");
        this.color=color;
        this.meterCount=meters;
        this.gapPercentage=gapPercentage;
        this.maxFrequency=maxFrequency;
        this.cutEnd=cutEnd;

        const audioContext=new AudioContext();
        const analyser=audioContext.createAnalyser();
        const audioSource=audioContext.createMediaElementSource(audio);
        audioSource.connect(analyser);
        analyser.connect(audioContext.destination);

        this.analyser=analyser;

        this.calculateSizes();
    }

    calculateSizes(){
        const gapTotalWidth=this.canvas.width/this.gapPercentage;
        const meterTotalWidth=this.canvas.width-gapTotalWidth;
        this.gapWidth=gapTotalWidth/(this.meterCount-1);
        this.meterWidth=meterTotalWidth/this.meterCount;
        // console.log(this.canvas.width,this.meterWidth,this.gapWidth);
        // console.log(`${this.canvas.width}=${this.meterWidth}*${this.meterCount}+${this.gapWidth}*${this.meterCount-1}`)
    }

    start(){
        window.start=this.start.bind(this);
        window.stop=this.stop.bind(this);
        this.frameUpdate=setInterval(this.renderFrame.bind(this),1000/FPS);
        $(window).resize(this.calculateSizes.bind(this));
    }

    stop(){
        setTimeout(()=>{
            clearInterval(this.frameUpdate);
            this.analyser.disconnect();
        },TILL_TIMEOUT);

    }

    renderFrame(){
        const array=new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(array);

        const step = Math.round(array.length*(1-this.cutEnd) / this.meterCount);
        this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
        for (let i = 0; i < this.meterCount; i++) {
            // var value = array[i * step];

            let sum=0;
            let count=0;
            let limit=clamp((i+1)*step,0,array.length);
            for(let j=i*step;j<limit;j++){
                const val=array[j];
                if(val){
                    sum+=val;
                    count++;
                }
            }

            let value=sum/count;
            const ratio=clamp(value/this.maxFrequency,0,1);
            const height=ratio*this.canvas.height;
            this.canvasContext.fillStyle = this.color;
            this.canvasContext.fillRect(i*(this.meterWidth+this.gapWidth) , this.canvas.height, this.meterWidth, -height);
        }
        // requestAnimationFrame(this.renderFrame.bind(this));
    }
}

export default Visualizer