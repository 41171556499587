import Visualizer, {clamp} from "./Visualizer"
import {PlayerSocketEvents} from "./PlayerSocketEvents"

const STREAM_SRC="https://live.superfm.ro/stream.mp3";

window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

$(document).ready(()=>{

    const playButton=$("#float-play-button");
    const player=$("#audioPlayer");
    const liveNotice=$("#live-notice");

    const djNameDiv=$("#currentDj");
    const djAvatar=$("#djAvatar");

    const songDiv=$("#nextSong");
    const canvasWrapper=$("#visualizerWrapper");

    const volumeMeter=$("#volumeMeter");
    const volumeDrag=$("#volumeDrag");

    const freeSpace=$("#free-space");

    let isPlaying=false;

    let audio;
    let volume=1;

    let canvas;
    let visualizer;
    let socketEvents;

    function onMouseMove(e){
        setVolume((e.pageX/window.innerWidth)*100);
    }

    function onTouchMove(e){
        let x;
        if(e.originalEvent && e.originalEvent.touches && e.originalEvent.touches[0]){
            x=e.originalEvent.touches[0].clientX;
        }else if(e.touches && e.touches[0]){
            x=e.touches[0].clientX;
        }
        console.log(x);
        setVolume((x/window.innerWidth)*100);
    }

    function setVolume(volume){
        if(volume<5){
            volume=0;
        }
        if(volume>95){
            volume=100;
        }
        $(volumeMeter).css("width",`${100-volume}%`);
        audio.volume=clamp(volume / 100, 0, 1);
    }

    function onMouseUp(){
        volumeDragFinished();
    }

    function volumeDragFinished(){
        console.log("finished");
        // $(volumeMeter).css("visibility","hidden");
        $("html").css("cursor","");
        $(volumeDrag).css("cursor","");
        document.removeEventListener("mousemove",onMouseMove);
        document.removeEventListener("mouseup",onMouseUp);
        document.removeEventListener("touchend",onMouseUp);
        document.removeEventListener("touchmove",onTouchMove);
    }

    function volumeDragStart(e){
        console.log(e);
        e.preventDefault();
        // $(volumeMeter).css("visibility","inherit");
        $("html").css("cursor","grabbing");
        $(volumeDrag).css("cursor","grabbing");

        if(e.type==="touchstart"){
            document.addEventListener("touchend",onMouseUp);
            document.addEventListener("touchmove",onTouchMove);
            onTouchMove(e);
        }else{
            document.addEventListener("mouseup",onMouseUp);
            document.addEventListener("mousemove",onMouseMove);
            onMouseMove(e);
        }
    }

    $(volumeDrag).mousedown(e=>volumeDragStart(e));
    $(volumeDrag).on("touchstart",e=>volumeDragStart(e));

    if(localStorage.getItem("beenPlayed")!=="true"){
        $(liveNotice).css("visibility","inherit");
    }

    function createCanvas(){
        canvas=document.createElement("canvas");
        canvas.setAttribute("id","visualizer");
        canvas.setAttribute("height",$(canvasWrapper).height());
        canvas.setAttribute("width",$(canvasWrapper).width());
        $(canvasWrapper).append(canvas);
        $(window).resize(()=>{
            console.log("resize");
            canvas.setAttribute("height",$(canvasWrapper).height());
            canvas.setAttribute("width",$(canvasWrapper).width());
        });
    }

    function play(){
        $(playButton).css("visibility","hidden");
        $(player).css("visibility","visible");
        isPlaying=true;

        audio=new Audio();
        audio.crossOrigin="anonymous";
        audio.src=`${STREAM_SRC}?nocache=${Math.random()}`;
        audio.play();
        setVolume(100);
        audio.onended=()=>{
            pause();
        };

        if(canvas){
            $(canvas).remove();
        }

        createCanvas();

        visualizer=new Visualizer(canvas,audio,"rgba(0,0,0,0.5)");
        visualizer.start();

        socketEvents=new PlayerSocketEvents();
        socketEvents.start();

        $(liveNotice).css("visibility","hidden");
        localStorage.setItem("beenPlayed",true);
    }

    function pause(){
        $(playButton).css("visibility","visible");
        $(player).css("visibility","hidden");

        audio.pause();
        audio.currentTime=0;
        audio.src="";
        audio=null;
        isPlaying=false;

        visualizer.stop();
    }

    function togglePlaying(){
        if(isPlaying){
            pause();
        }else{
            play();
        }
    }

    function detectIE() {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            const rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        return false;
    }


    $(".controlButton").on( "click", function(e)
    {
        e.preventDefault();
        togglePlaying();

        calculateFreeSpace();
    });

    $("#pauseButton").on("click",()=>{
        if (detectIE() === false)
        {
            $(djNameDiv).css("-webkit-touch-callout","none");
            $(djNameDiv).css("-webkit-user-select","none");
            $(djNameDiv).css("-khtml-user-select","none");
            $(djNameDiv).css("-moz-user-select","none");
            $(djNameDiv).css("-ms-user-select","none");
            $(djNameDiv).css("user-select","none");

            $(songDiv).css("-webkit-touch-callout","none");
            $(songDiv).css("-webkit-user-select","none");
            $(songDiv).css("-khtml-user-select","none");
            $(songDiv).css("-moz-user-select","none");
            $(songDiv).css("-ms-user-select","none");
            $(songDiv).css("user-select","none");

            $(djAvatar).css("-webkit-touch-callout","none");
            $(djAvatar).css("-webkit-user-select","none");
            $(djAvatar).css("-khtml-user-select","none");
            $(djAvatar).css("-moz-user-select","none");
            $(djAvatar).css("-ms-user-select","none");
            $(djAvatar).css("user-select","none");
        }
    });

    $("#currentDj, #nextSong").on( "click", function() 
    {
        $(djNameDiv).css("-webkit-touch-callout","text");
        $(djNameDiv).css("-webkit-user-select","text");
        $(djNameDiv).css("-khtml-user-select","text");
        $(djNameDiv).css("-moz-user-select","text");
        $(djNameDiv).css("-ms-user-select","text");
        $(djNameDiv).css("user-select","text");

        $(songDiv).css("-webkit-touch-callout","text");
        $(songDiv).css("-webkit-user-select","text");
        $(songDiv).css("-khtml-user-select","text");
        $(songDiv).css("-moz-user-select","text");
        $(songDiv).css("-ms-user-select","text");
        $(songDiv).css("user-select","text");
    });

    $("window").resize(()=>{
        if(isPlaying){
            calculateFreeSpace();
        }
    });

    function calculateFreeSpace(){
        if(!isPlaying){
            $(freeSpace).css("height",30);
        }else{
            $(freeSpace).css("height",$(player).css("height"));
        }
    }
});
