import SocketIO from "socket.io-client"

let songNameEl;
let djNameEl;
let djAvatarEl;

$(()=>{
    songNameEl=$("#nextSong");
    djNameEl=$("#currentDj");
    djAvatarEl=$("#djAvatar");
});

export class PlayerSocketEvents{
    start(){
        this.socket=SocketIO();
        this.setEventListeners();
    }

    stop(){
        this.socket.close();
    }

    setEventListeners(){
        this.socket.on("djLive",PlayerSocketEvents.setLiveDj);
        this.socket.on("songList",this.onSongList.bind(this));
        this.socket.on("nextSong",this.onNextSong.bind(this));
    }

    onNextSong(song){
        if(!song || !song.name ||!song.liveIn){
            return;
        }
        this.setNextSong(song.name,song.liveIn);
    }

    onSongList(songList){
        if(!songList || songList.length<0){
            return;
        }
        songList.forEach(song=>this.setNextSong(song.name,song.liveIn));
    }

    static setLiveDj(dj){
        console.log("set dj live",dj);
        if(!dj || !dj.name || !dj.image ){
            $(djNameEl).text("NON-STOP Super FM");
            $(djAvatarEl).attr("src","/static/images/non_stop_sfm.png");
        }else{
            $(djNameEl).text(dj.name);
            $(djAvatarEl).attr("src",dj.image);
        }
    }

    setSongNameSize(song){
        if(window.innerWidth<500){
            if(song.length<15){
                $(songNameEl).css("font-size","0.95rem")
            }else if(song.length<30){
                $(songNameEl).css("font-size","0.82rem")
            }else if(song.length<65){
                $(songNameEl).css("font-size","0.70rem")
            }else{
                $(songNameEl).css("font-size","0.55rem")
            }
        }else{
            $(songNameEl).css("font-size","")
        }
    }

    setNextSong(song,delay){
        setTimeout(()=>{
            $(songNameEl).text(song);
            this.setSongNameSize(song);
        },delay)
    }
}
